export class EcompleteTagManager extends HTMLElement {

    draw(googleTag) {
        return `<!-- Ecomplete Tag Manager Tracking ${googleTag} -->`;
    }

    stripTags(str) {
        console.log (str);
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
    }

    getProductIds(self, selector) {
        if (!selector) {
            selector = '.product';
        }
        let ids = document.querySelectorAll(selector);
        let productIds = [];
        ids.forEach(function(element) {
            let productId =  element.innerHTML;
            productId = self.stripTags(productId);
            productIds.push(productId);
        });
        console.log(productIds);
        return productIds;
    }

    getProductPrice(self, selector) {
        if (!selector) {
            selector = '.product .price';
        }
        let ids = document.querySelectorAll(selector);
        let productPrices = [];
        ids.forEach(function(element) {
            let price =  element.innerHTML;
            price = self.stripTags(price);
            productPrices.push(price);
        });
        console.log(productPrices);
        return productPrices;
    }

    getProductSkus() {
        let skus = document.querySelectorAll('.sku');
        console.log(skus);
        let productSkus = [];
        skus.forEach(function(element) {
            productSkus.push(element.id);
        });
        console.log(productSkus);
        return productSkus;
    }

    getPageType() {
        let urlPath = window.location.pathname;
        console.log ('Determine page type based on ', urlPath);
        if (urlPath === '/') {
            return 'home';
        } else if (window.location.pathname.indexOf('/checkout/cart/') != -1) {
            return 'cart';
        } else {
            return 'unknown';
        }
    }

    constructor(){
        super();
        let googleTagParams = {};
        let googleTag =  this.getAttribute('google-tag');
        let trackingDescription = this.getAttribute('tracking-description');
        let trackingEvent = this.getAttribute('tracking-event');
        let productSelector = this.getAttribute('product-selector');
        let priceSelector = this.getAttribute('price-selector');

        //future functionality
        //let googleScript = document.createElement('script');
        //googleScript.async = true;
        //googleScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTag}`;

        //Create the data layer if it doesn't exist
        if (!window['dataLayer']) {
            window['dataLayer'] = window['dataLayer'] || [];
        }

        //Determine the page type
        googleTagParams['ecomm_pagetype'] = this.getPageType();
        googleTagParams['ecomm_prodid'] = this.getProductIds(this, productSelector).join(',');
        googleTagParams['ecomm_description'] = trackingDescription;
        googleTagParams['ecomm_totalvalue'] = this.getProductPrice(this, priceSelector).join(',');

        //this.getProductSkus();

        this.innerHTML = this.draw(googleTag);
        console.log('Pushing event', googleTagParams);
        window['dataLayer'].push({'event': trackingEvent, 'google_tag_params': googleTagParams});

    }
}